import styled from "styled-components";

export const ValuesContainer = styled.div`
  background-color: #f9f9f9;

  .container {
    max-width: 1600px;
    width: 80%;
    padding: 80px 0;
    margin: 0 auto;

    h3 {
      text-align: center;
      margin-bottom: 80px;
    }

    .cards {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @keyframes slide-top {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-8px);
        }
      }
      @keyframes slide-bottom {
        0% {
          transform: translateY(-8px);
        }
        100% {
          transform: translateY(0);
        }
      }

      .card::before {
        content: "";
        position: absolute;
        background-color: #0b4df5;
        opacity: 0.4;
        top: -300px;
        left: 0;
        width: 1000px;
        height: 1000px;
        z-index: -1;
        transition: transform 1s;
        transform-origin: 0 0;
        transform: scaleY(0);
        rotate: 45deg;
      }

      .card:hover {
        &::before {
          transform: scaleY(1);
        }
      }
      .card {
        width: 32%;
        padding: 40px;
        background-color: unset;
        border-radius: 20px;
        box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
        animation: slide-bottom 0.5s both;
        position: relative;
        overflow: hidden;

        &:hover {
          animation: slide-top 0.5s both;
        }

        img {
          margin-bottom: 35px;
        }

        h5 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          margin-bottom: 35px;
        }

        p {
          font-size: 17px;
          font-weight: 400;
          line-height: 27px;
          text-align: justify;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .container {
      .cards {
        .card {
          h5 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 35px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: justify;
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .container {
      .cards {
        .card {
          h5 {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 35px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: justify;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .container {
      width: 90%;
    }
  }

  @media (max-width: 900px) and (min-width: 701px) {
    .container {
      width: 90%;
      padding: 100px 0;

      h3 {
        margin-bottom: 80px;
      }

      .cards {
        flex-wrap: wrap;

        .card::before {
          top: -250px;
        }

        .card {
          width: 48%;

          &:last-child {
            margin: 30px auto 0 auto;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .container {
      width: 90%;
      padding: 100px 0;

      h3 {
        margin-bottom: 80px;
      }

      .cards {
        flex-direction: column;
        align-items: center;

        .card::before {
          top: -450px;
        }

        .card {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  
  @media (max-width: 600px) {
    .container {

      .cards {

        .card::before {
          top: -400px;
        }

        .card {
          width: 100%;
          margin-bottom: 20px;
          padding: 30px;
        }
      }
    }
  }
  
  @media (max-width: 550px) {
    .container {

      .cards {

        .card::before {
          top: -300px;
        }

        .card {
          width: 100%;
          margin-bottom: 20px;
          padding: 30px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    .container {

      .cards {

        .card::before {
          top: -250px;
        }

        .card {
          width: 100%;
          margin-bottom: 20px;
          padding: 30px;
        }
      }
    }
  }
`;
