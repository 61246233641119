import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: relative;
  width: 100%;
  height: 80vh;

  .slider {
    .slick-arrow {
      z-index: 9 !important;
      width: 50px;
      height: 50px;
    }
    .slick-arrow:before {
      font-size: 50px;
    }
    .slick-prev {
      left: 25px !important;
    }

    .slick-next {
      right: 25px !important;
    }
  }

  .custom-slide {
    img {
      width: 100% !important;
      height: 80vh !important;
      filter: brightness(50%);
    }
  }

  .Content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      width: 50%;
      margin: 0 auto;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      color: #ffffff;
    }

    a {
      padding: 15px 50px;
      width: fit-content;
      margin: 0 auto;
      margin-top: 74px;
      background-color: unset;
      border: unset;
      border-radius: 10px;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      color: #ffffff;
      cursor: pointer;
      transition: color 0.4s linear;
      position: relative;
      overflow: hidden;
    }

    a::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #90a955;
      border-radius: 10px;
      z-index: -2;
    }

    a::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #f2830d;
      border-radius: 10px;
      z-index: -1;
      transition: transform 0.5s;
      transform-origin: 0 0;
      transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
      transform: scaleX(0);
    }

    a:hover {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  @media (max-width: 1000px) {
    width: 100vw;
    height: 80vh;

    .slider {
      .slick-arrow {
        z-index: 9 !important;
        width: 20px;
        height: 20px;
      }
      .slick-arrow:before {
        font-size: 20px;
      }
      .slick-prev {
        left: 15px !important;
      }

      .slick-next {
        right: 15px !important;
      }
    }

    .custom-slide {
      img {
        width: 100% !important;
        height: 80vh !important;
        filter: brightness(50%);
      }
    }

    .Content {
      .text {
        width: 90%;
        font-size: 27px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;
        color: #ffffff;
      }

      a {
        padding: 10px 30px;
        margin-top: 74px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 700px) {
    .Content {
      .text {
        width: 90%;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
`;
