import React from 'react'
import { HrContainer } from './style'

export default function Hr() {
  return (
    <HrContainer>
      <hr />
    </HrContainer>
  )
}
