import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  .left {
    width: 48%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: 48%;

    h4 {
      font-size: 25px;
      margin-bottom: 20px;
      color: #000;
    }

    p {
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      margin-bottom: 20px;
    }

    .hidden {
      display: none;
    }

    .content {
      .p {
        font-size: 17px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;

        .Icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
          color: #f2830d;
        }
      }

      ul {
        margin: 15px;

        li {
          font-size: 15px;
          font-weight: 500;
          line-height: 25px;
          color: #000;
          margin-bottom: 10px;
          display: flex;
          align-items: baseline;

          .cube {
            width: 10px;
            height: 10px;
            background-color: #f2830d;
            margin-right: 10px;
          }
          .text {
            width: 95%;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .right {
      h4 {
        font-size: 20px;
        margin-bottom: 15px;
      }

      p {
        font-size: 15px;
        line-height: 25px;
        text-align: justify;
        margin-bottom: 20px;
      }

      .content {
        .p {
          font-size: 15px;
          line-height: 25px;
        }

        ul {
          margin: 10px;

          li {
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    .left {
      width: 100%;
      margin-bottom: 20px;
    }
    .right {
      width: 100%;

      h4 {
        font-size: 15px;
        margin-bottom: 10px;
        color: #000;
      }

      p {
        font-size: 13px;
        line-height: 23px;
        text-align: justify;
        margin-bottom: 20px;
      }

      .content {
        .p {
          font-size: 13px;
          line-height: 23px;
        }

        ul {
          margin: 10px;

          li {
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
    }
  }
`;
