import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Error from "./Pages/Error";
import Home from "./Pages/Home";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
]);

export default routers;
