import React, { useState } from "react";
import MenuList from "../../Components/MenuList";
import About from "./parts/About";
import Businesses from "./parts/Businesses";
import Header from "./parts/Header";
import Offers from "./parts/Offers";
import Values from "./parts/Values";
import Footer from "../../Components/Footer";
import Contact from "./parts/Contact";
import Partners from "./parts/Partners";

export default function Home() {
  const [menuList, setMenuList] = useState(false);

  const isOpen = (value) => {
    setMenuList(value)
  }
  return( 
  <div>
      <Header isOpen={isOpen} />
      <About />
      <Values />
      <Offers />
      <Businesses />
      <Partners />
      <Contact />
      <Footer />
      {menuList && <MenuList isOpen={isOpen} />}
  </div>
  );
}
