import styled from "styled-components";

export const ContactContainer = styled.div`
  background-color: #f9f9f9;

  padding: 80px 0;
  

  h3 {
    text-align: center;
    margin-bottom: 80px;
  }
  p {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    margin-bottom: 80px;
  }

  .FormContainer {
    max-width: 1600px;
    width: 80%;
    margin: 0 auto;
    background-color: #f9f9f9;
    padding: 50px;
    border-radius: 20px;
    box-shadow: rgba(150, 158, 166, 0.2) 0px 8px 24px;

    .fromInputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: self-end;

      .fromGroupe {
        width: 48%;

        input {
          width: 100%;
          height: 48px;
          margin-bottom: 30px;
          padding: 20px;
          border: 1px solid #90a955;
          border-radius: 10px;
        }

        .error {
          border: 1px solid red;
        }
      }
    }

    .error {
      border: 1px solid red;
    }

    textarea {
      width: 100%;
      height: 200px;
      margin-bottom: 30px;
      padding: 20px;
      border: 1px solid #90a955;
      border-radius: 10px;
    }

    button {
      display: block;
      background-color: #90a955;
      padding: 10px 20px;
      border: unset;
      color: #ffffff;
      border-radius: 10px;
      margin: 0 auto;
      cursor: pointer;
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  @media (max-width: 1000px) {
    width: 90%;

    .FormContainer {
      padding: 50px 30px;

      .fromInputs {
        .fromGroupe {
          width: 100%;

          input {
            padding: 10px;
          }
        }
      }

      textarea {
        width: 100%;
        padding: 10px;
      }
    }
  }
`;
export const ErrorMsg = styled.div`
  color: red;
  padding: 10px 0;
`;
export const SuccessMsg = styled.div`
  color: red;
  padding: 10px 0;
  text-align: center;
`;
