import React from "react";
import { Container } from "./style";
import b3 from "../../../../../Assets/Images/b3.jpg";
import { useTranslation } from "react-i18next";

export default function Digitalisation() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="left">
        <img src={b3} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title3")}</h4>
        <div className="wrapper">
          <p>{t("Digitalisation.DigitalisationP1")}</p>
          <p>{t("Digitalisation.DigitalisationP2")} <a target="blank" href="https://www.encardio.com/">ENCARDIO RITE</a></p>
        </div>
      </div>
    </Container>
  );
}
