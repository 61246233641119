import React from "react";
import icons from "../../Assets/Svg/icons.svg";

export default function Icon({ iconName, width, height }) {
  // const { width, height, iconname, ...rest } = props;

  return (
    <svg
      // {...rest}
      aria-hidden="true"
      width={width ? width : "1em"}
      height={height ? height : "1em"}
    >
      <use xlinkHref={icons + "#" + iconName}></use>
    </svg>
  );
}
