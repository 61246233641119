import React from "react";
import { Container } from "./style";
import b44 from "../../../../../Assets/Images/b44.jpg";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../Components/Icon";
import { useState } from "react";

export default function Railway() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Container>
      <div className="left">
        <img src={b44} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title4")}</h4>
        <div className="wrapper">
          <p>{t("Railway.RailwayP1")}</p>
          <div className="content">
            <div className="list-1">
              <div className="p" onClick={() => handleTabClick(0)}>
                <div className="Icon">
                  <Icon iconName="ArrowDown" />
                </div>
                {t("Railway.list1.p")}
              </div>
              <ul className={activeTab === 0 ? "" : "hidden"}>
                <li>
                  <div className="cube"></div>
                  <div className="text">{t("Railway.list1.value1")}</div>
                </li>
                <li>
                  <div className="cube"></div>
                  <div className="text">{t("Railway.list1.value2")}</div>
                </li>
                <li>
                  <div className="cube"></div>
                  <div className="text">{t("Railway.list1.value3")}</div>
                </li>
                <li>
                  <div className="cube"></div>
                  <div className="text">{t("Railway.list1.value4")}</div>
                </li>
              </ul>
            </div>
            <div className="list-1">
              <div className="p" onClick={() => handleTabClick(1)}>
                <div className="Icon">
                  <Icon iconName="ArrowDown" />
                </div>
                {t("Railway.list2.p")}
              </div>
              <ul className={activeTab === 1 ? "" : "hidden"}>
                <li>
                  <div className="circle"></div>
                  <div className="text">{t("Railway.list2.p1")}</div>
                </li>
                <ul>
                    <li>
                      <div className="cube"></div>
                      <div className="text">{t("Railway.list2.value1")}</div>
                    </li>
                    <li>
                      <div className="cube"></div>
                      <div className="text">{t("Railway.list2.value2")}</div>
                    </li>
                    <li>
                      <div className="cube"></div>
                      <div className="text">{t("Railway.list2.value3")}</div>
                    </li>
                  </ul>
                <li>
                  <div className="circle"></div>
                  <div className="text">{t("Railway.list2.p2")}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
