import React from "react";
import { Container } from "./style";
import b77 from "../../../../../Assets/Images/b77.jpg";
import { useTranslation } from "react-i18next";

export default function Environment() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="left">
        <img src={b77} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title5")}</h4>
        <p>{t("Environment.EnvironmentP")}</p>
        <div className="content">
          <ul>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Environment.list.value1")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Environment.list.value2")}</div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}
