const FR_Language = {
  // title: "CAPVIEW ENGINEERING & BUILDING",
  pages: {
    home: "Accueil",
    about: "À propos",
    our_businesses: "Nos Métiers",
    achievements: "Réalisations",
    partner: "Partenaire",
    client: "Clients",
    contact: "Contactez-nous",
  },
  businesseList: {
    title1: "Département Ingénierie et méthode",
    title2: "Département Conseil, assistance et Formation",
    title3: "Instrumentation, contrôle et digitalisation",
    title4: "Département ferroviaire",
    title5: "Travaux d'Infrastructure",
    title6: "Travaux de Bâtiment et aménagement urbain",
  },
  header: {
    content:
      "Nous sommes pleinement investis dans le succès de votre organisme et de vos projets!",
    button: "DÉCOUVREZ-NOUS",
  },
  about: {
    aboutH3: "Qui sommes nous?",
    aboutP1:
      "Avec une équipe d'experts dotée d'une longue expérience et un large savoir faire dans différents projets d'envergure dans les domaines de l’infrastructure, du bâtiment, des installations industrielles et le secteur ferroviaire , nous avons créé",
    aboutP2: "pour accompagner votre croissance et développement.",
    info: "Plus d’info",
  },
  values: {
    valuesH3: "NOS VALEURS",
    value1: {
      title: "On fait les choses bien",
      content:
        "Nous agissons toujours avec intégrité – en assumant la responsabilité de notre travail, en prenant soin de nos employés et en restant concentrés sur la sécurité et la durabilité. Nous investissons dans nos clients, nos gens et nos collectivités afin de croître ensemble.",
    },
    value2: {
      title: "On vise plus haut",
      content:
        "On relève toujours la barre plus haut afin de livrer avec excellence.Nous sommes engagés envers nos clients en apportons des solutions novatrices qui mènent à une croissance rentable et à un succès partagé.",
    },
    value3: {
      title: "Nous vivons l’inclusion",
      content:
        "Nous mettons les gens au cœur de nos activités, et nous mettons un accent inégalé sur l’inclusion avec une équipe diversifiée de visionnaires, de penseurs et d’acteurs. Nous embrassons toutes les perspectives, collaborons pour avoir un impact positif.",
    },
  },
  offres: {
    offresH3: "NOS OFFRES",
    value1: {
      title: "L'ingénierie des projets et contrôle",
      content:
        "Propose un panel de prestations liées à l'ingénierie des projets et contrôle :",
      subContent: {
        value1: "Conception et études.",
        value2: "Suivi et assistance technique.",
        value3: "Instrumentation, contrôle et digitalisation.",
      },
    },
    value2: {
      title: "Formations",
      content:
        "Vous propose également diverses formations disponibles sur notre catalogue de formation.",
    },
    value3: {
      title: "Solutions technique et travaux d'exécution",
      content:
        "Propose une panoplie de solutions d’étude et travaux d'exécution pour ses clients tout au long des phases de projets. Ces solutions et services couvrent les départements suivants :",
      subContent: {
        value1: "Département ferroviaires.",
        value2: "Environnement et enérgies renouvelables.",
        value3:
          "Travaux d'Infrastructure, de Bâtiment et d'aménagement urbain.",
      },
    },
  },
  businesses: {
    businessesH3: "Nos Métiers",
    title1: "Département Ingénierie et méthode",
    title2: "Département Conseil, assistance et Formation",
    title3: "Instrumentation, contrôle et digitalisation",
    title4: "Département ferroviaire",
    title5: "Environnement et enérgies renouvelables",
    title6: "Travaux d'Infrastructure, de Bâtiment et d'aménagement urbain",
  },
  Partners: {
    PartnersH3: "NOS PARTENAIRES",
    p: `Afin de renforcer son département "ICD: Instrumentation, Contrôle et Digitalisation" , CAPVIEW Engineering and Building a conclu un partenariat de collaboration au Maroc et en Afrique avec  ENCARDIO RITE , un le leader mondial en matière de fabrication, de commercialisation et d'installation d'instruments pour les différents ouvrages et installation,  comme: les tunnels, les ponts, les voies ferrées, les barrages, les installations industrielles et nucléaire...)`,
  },
  footer: {
    footerP:
      "Dotée d’une équipe d’experts avec une longue expérience et un large savoir faire dans les domaines de l’infrastructure, du bâtiment, des installations industrielles et le secteur ferroviaire.",
    Links: "Liens",
  },
  Engineering: {
    EngineeringP:
      "Grâce à une équipe d’experts dotés d’une longue expérience et un large savoir faire, nous avons développé une véritable expertise et un savoir-faire reconnu dans la réalisation de différentes prestations, à savoir :",
    list1: {
      p: "Nos prestations portent sur :",
      value1:
        "Les études de définition, d’avant-projet et d’exécution de projets routiers, autoroutiers et ferroviaires (en site propre, des rectifications, des dédoublements,.).",
      value2:
        "Les études de structure et lots techniques pour les bâtiments toute catégorie confondues.",
      value3:
        "Les études liées à l’aménagement urbain: de voirie et réseau divers (assainissement, électrification, eau potable…).",
      value4: "Les études d’aménagement des grandes plateformes.",
      value5:
        "Les études de mobilité (Trafic et rentabilité économique , plan de déplacement urbain…).",
      value6: "Les études des ouvrages d’art et Calcul des structures.",
      value7: "Les études stratégiques (schémas directeurs).",
      value8: "Les études d’impact environnemental.",
    },
    list2: {
      p: "Nos domaines d’intervention :",
      value1: "Ferroviaire",
      value2: "Aménagement urbain",
      value3: "Bâtiment",
      value4: "Routes et autoroutes",
      value5: "Ouvrages d’art et génie civil",
      value6: "Environnement",
    },
  },
  Advisory: {
    AdvisoryP:
      "Avec un réseau ramifié de consultants et une équipe d’experts, nous avons développé une véritable expertise et un savoir-faire reconnu dans la réalisation de différentes prestations, à savoir :",
    list1: {
      value1: "Management de projets",
      value2: "Management stratégiques des entreprises",
      value3:
        "Mise en place d’un système QSE et accompagnement à la certification",
      value4:
        "Evaluation des politique entreprise par des organismes privés ou public",
      value5: "Les expertises, assistance technique et maîtrise d’oeuvre.",
      value6:
        "Les audits et suivi des travaux de réalisation des projets d’infrastructure de transport.",
      value7:
        "Elaboration, suivi ou traitement des réclamations pour le compte du prestataire ou le maitre d’ouvrage",
    },
    AdvisoryP2:
      "Avec des formateurs expérimentés dans plusieurs domaines, nous mettons à votre disposition et à celle de vos équipes une large gamme de formations techniques et fonctionnelles liées principalement aux domaines d’activité de CAPVIEW E&B.",
  },
  Digitalisation: {
    DigitalisationP1:
      "Dans un monde ou la technologie prends un espace important dans la gestion, la simplification et la maitrise des processus de réalisations et de contrôle des projets d'infrastructure et bâtiment, CAPVIEW E&B grâce à une équipe d’informaticiens et d'ingenieurs expérimentés (Civil, hydraulicien, géotechnicien ...) et un savoir faire métier, vous propose plusieurs solutions et un panel de prestations liées à l'instrimentation, contrôle et à la maintenance préventive de vos ouvrages.",
    DigitalisationP2:
      "CAPVIEW E&B a renforcé ce département en tissant un partenariat avec le leader mondial en matière de fabrication, de commercialisation et d'instalation d'instrument pour les differents ouvrage d'art à savoir l'entreprise ",
  },
  Railway: {
    RailwayP1:
      "Grâce à une équipe d’experts dotés d’une longue expérience et un large savoir-faire dans le domaine ferroviaire et ayant participé à la réalisation du projet à grande vitesse du Maroc, CAPVIEW E&B vous propose les prestations de la ligne de grange vitesse :",
    list1: {
      p: "Ingénierie et assistance :",
      value1: "Ordonnancement, pilotage et coordination des Projets.",
      value2: "Maîtrise d’œuvre technique.",
      value3: "Assistance au maitre d’ouvrage.",
      value4: "Vérification des études et optimisation.",
    },
    list2: {
      p: "Travaux d’exécution :",
      p1: "Travaux d’infrastructure ferroviaire :",
      p2: "Travaux de maintenance de l’infrastructure et superstructure",
      value1: "Terrassement.",
      value2: "Ouvrage hydrauliques.",
      value3: "Ouvrages d’art.",
    },
  },
  Infrastructure: {
    list1: {
      p: "Travaux d’infrastructure :",
      value1: "Ouvrage d’arts",
      value2: "Travaux routiers et autoroutier",
      value3: "Voiries structurantes",
    },
    list2: {
      p: "Travaux de Bâtiment et aménagement urbain :",
      value1: "Bâtiments résidentiels",
      value2: "Bâtiments culturels et équipements publics",
      value3: "Bâtiments tertiaires",
      value4: "Centres commerciaux et loisirs",
      value5: "Bâtiments industriels",
      value6: "Voiries et réseaux divers (assainissement, éclairage public…)",
      value7: "Trottoirs, espaces vert et aires de jeu",
    },
  },
  Environment: {
    EnvironmentP:
      "L’énergie verte est devenue un enjeu important, elle présente aujourd’hui des perspectives de développement exponentielles au Maroc et en Afrique surtout avec la flambée des prix des énergies faucilles. Dans cette perspective Capview E&B et grâce à une équipe de spécialistes dans le domaine et un matériel spécifique vous offre des prestations :",
    list: {
      value1: "D'étude et de conception liés aux énergies vertes",
      value2:
        "Des travaux de mise en œuvre de la plate forme à la mise en service",
    },
  },
  Contact: {
    p: "Si vous avez des questions sur les services que nous fournissons, utilisez simplement le formulaire ci-dessous. Nous essayons de répondre à toutes les questions et commentaires dans les 24 heures.",
    namePlace: "Nom et Prénom*",
    nameError: "Nom et Prénom requis",
    objetPlace: "Objet",
    emailError: "Entrer un email valide",
    emailErrorMatch: "Email requis",
    telError: "Téléphone requis",
    telErrorMatch: "Entrez un numéro de téléphone valide",
    messageError: "Message requis",
    btn: "Envoyer le message",
  },
};

export default FR_Language;
