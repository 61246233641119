const EN_Language = {
  pages: {
    home: "Home",
    about: "About",
    our_businesses: "Our businesses",
    achievements: "Achievements",
    partner: "Partner",
    client: "Clients",
    contact: "Get in Touch",
  },
  businesseList: {
    title1: "Engineering and Method Department",
    title2: "Advisory, Assistance and Training Department",
    title3: "Instrumentation, Control and Digitalisation",
    title4: "Railway Department",
    title5: "Infrastructure Works",
    title6: "Building and Urban Development Work",
  },
  header: {
    content:
      "We are fully invested in the success of your organization and your projects!",
    button: "DISCOVER US",
  },
  about: {
    aboutH3: "Who are we?",
    aboutP1:
      "with a team of experts with many years of experience and a wide know-how in various major projects in the fields of infrastructure, building, industrial installations and the railway sector, we created",
    aboutP2: "to support your growth and development.",
    info: "More info",
  },
  values: {
    valuesH3: "OUR VALUES",
    value1: {
      title: "We do things right",
      content:
        "We always act with integrity – by taking responsibility for our work, taking care of our employees and staying focused on safety and sustainability. We invest in our customers, people and communities to grow together.",
    },
    value2: {
      title: "We aim higher",
      content:
        "We always raise the bar to deliver with excellence. We are committed to our clients by providing innovative solutions that lead to profitable growth and shared success.",
    },
    value3: {
      title: "We experience inclusion",
      content:
        "We put people at the heart of our business, and we put an unparalleled focus on inclusion with a diverse team of visionaries, thinkers and actors. We embrace all perspectives, collaborate to have a positive impact.",
    },
  },
  offres: {
    offresH3: "OUR OFFERS",
    value1: {
      title: "Project engineering and control",
      content:
        "Offers a range of services related to the engineering of projects and control:",
      subContent: {
        value1: "Design and studies.",
        value2: "Monitoring and technical assistance.",
        value3: "Instrumentation, control and digitization.",
      },
    },
    value2: {
      title: "Formations",
      content:
        "We also offer various training courses available on our training catalogue.",
    },
    value3: {
      title: "Technical solutions and execution works",
      content:
        "Offers a range of study solutions and execution work for its clients throughout the project phases. These solutions and services cover the following departments:",
      subContent: {
        value1: "Railway department.",
        value2: "Environment and renewable energy.",
        value3: "Infrastructure, building and urban development work.",
      },
    },
  },
  businesses: {
    businessesH3: "Our businesses",
    title1: "Engineering and Method Department",
    title2: "Advisory, Assistance and Training Department",
    title3: "Instrumentation, Control and Digitalisation",
    title4: "Railway Department",
    title5: "Environment and renewable energy",
    title6: "Infrastructure, building and urban development work",
  },
  Partners: {
    PartnersH3: "OUR PARTNERS",
    p: `In order to strengthen its "ICD: Instrumentation, Control and Digitalization" department, CAPVIEW Engineering and Building has entered into a collaborative partnership in Morocco and Africa with ENCARDIO RITE, a world leader in the manufacture, marketing and installation of instruments for the various works and installations, such as: tunnels, bridges, railways, dams, industrial and nuclear installations, etc.)`,
  },
  footer: {
    footerP:
      "Equipped with a team of experts with a long experience and a wide know-how in the fields of infrastructure, building, industrial installations and the railway sector.",
    Links: "Links",
  },
  Engineering: {
    EngineeringP:
      "Thanks to a team of experts with a long experience and extensive know-how, we have developed a real expertise and recognized know-how in the realization of various services, namely:",
    list1: {
      p: "Our services cover:",
      value1:
        "Studies of definition, preliminary design and execution of road, motorway and railway projects (in own site, rectifications, duplication,.).",
      value2:
        "Structural studies and technical lots for buildings of all categories.",
      value3:
        "Studies related to urban development: roads and various networks (sanitation, electrification, drinking water, etc.).",
      value4: "Design studies of large platforms.",
      value5:
        "Mobility studies (traffic and economic profitability, urban displacement plan, etc.).",
      value6: "Studies of works of art and Calculation of structures.",
      value7: "Strategic studies (master plans).",
      value8: "environmental impact studies.",
    },
    list2: {
      p: "Our areas of intervention:",
      value1: "Railway",
      value2: "Urban planning",
      value3: "Building",
      value4: "Roads and highways",
      value5: "Structures and civil engineering",
      value6: "Environment",
    },
  },
  Advisory: {
    AdvisoryP:
      "With an extensive network of consultants and a team of experts, we have developed a real expertise and recognized know-how in the realization of various services, namely:",
    list1: {
      value1: "project management",
      value2: "Strategic management of enterprises",
      value3: "Implementation of a QSE system and support for certification",
      value4: "Evaluation of policies undertaken by private or public bodies",
      value5: "Expertise, technical assistance and project management.",
      value6:
        "Audits and follow-up of work on transport infrastructure projects.",
      value7:
        "Preparation, monitoring or processing of complaints on behalf of the service provider or the contracting authority",
    },
    AdvisoryP2:
      "With experienced trainers in several fields, we provide you and your teams with a wide range of technical and functional training related mainly to CAPVIEW’s areas of activity.",
  },
  Digitalisation: {
    DigitalisationP1:
      "In a world where technology takes an important space in the management, simplification and mastery of the processes of realisation and control of infrastructure and building projects, CAPVIEW E&B thanks to a team of experienced computer scientists and engineers (Civil, hydraulician, geotechnical engineer, etc.) and business know-how, offers several solutions and a range of services related to the instruction, control and preventive maintenance of your structures.",
    DigitalisationP2:
      "CAPVIEW E&B has strengthened this department by establishing a partnership with the world leader in the manufacture, marketing and installation of instruments for the various works of art, namely the company ",
  },
  Railway: {
    RailwayP1:
      "Thanks to a team of experts with a long experience and a wide know-how in the railway field and having participated in the realization of the high-speed project of Morocco, CAPVIEW E&B offers you the services of the barn line speed:",
    list1: {
      p: "Engineering and support:",
      value1: "Project scheduling, steering and coordination.",
      value2: "Technical project management.",
      value3: "Assistance to the contracting authority.",
      value4: "Audit of studies and optimization.",
    },
    list2: {
      p: "Construction Work:",
      p1: "Rail Infrastructure Works:",
      p2: "Infrastructure maintenance work and superstructure",
      value1: "Earthworks.",
      value2: "Hydraulic structures.",
      value3: "works of art.",
    },
  },
  Infrastructure: {
    list1: {
      p: "Infrastructure Works:",
      value1: "Art work",
      value2: "Road and motorway works",
      value3: "Structuring roads",
    },
    list2: {
      p: "Building and urban development work:",
      value1: "residential buildings",
      value2: "Cultural buildings and public facilities",
      value3: "Tertiary buildings",
      value4: "Shopping and leisure centres",
      value5: "Industrial buildings",
      value6: "Various roads and networks (sanitation, street lighting, etc.)",
      value7: "Sidewalks, green spaces and play areas",
    },
  },
  Environment: {
    EnvironmentP:
      "Green energy has become an important issue, it now presents exponential development prospects in Morocco and Africa especially with the soaring prices of sickle energy. In this perspective Capview E&B and thanks to a team of specialists in the field and a specific equipment offers you:",
    list: {
      value1: "Green Energy Design and Design",
      value2: "From platform implementation work to commissioning",
    },
  },
  Contact: {
    p: "If you have any questions about the services we provide, simply use the form below. We try to answer all questions and comments within 24 hours.",
    namePlace: "Full name*",
    nameError: "Full name is required",
    objetPlace: "Object",
    emailError: "Enter a valid email",
    emailErrorMatch: "Email is required",
    telError: "Phone required",
    telErrorMatch: "Enter a valid phone number",
    messageError: "Message is required",
    btn: "Send message",
  },
};

export default EN_Language;
