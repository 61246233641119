import React from "react";
import { Container } from "./style";
import b2 from "../../../../../Assets/Images/b2.jpg";
import { useTranslation } from "react-i18next";

export default function Advisory() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="left">
        <img src={b2} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title2")}</h4>
        <p>{t("Advisory.AdvisoryP")}</p>
        <div className="content">
          <ul>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value1")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value2")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value3")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value4")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value5")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value6")}</div>
            </li>
            <li>
              <div className="cube"></div>
              <div className="text">{t("Advisory.list1.value7")}</div>
            </li>
          </ul>
        </div>
        <p>{t("Advisory.AdvisoryP2")}</p>
      </div>
    </Container>
  );
}
