import styled from "styled-components";

export const BusinessesContainer = styled.div`
  background-color: #f9f9f9;
  .container {
    max-width: 1600px;
    width: 80%;
    padding: 80px 0;
    margin: 0 auto;

    h3 {
      text-align: center;
      margin-bottom: 80px;
    }

    .cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @keyframes slide-top {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-8px);
        }
      }
      @keyframes slide-bottom {
        0% {
          transform: translateY(-8px);
        }
        100% {
          transform: translateY(0);
        }
      }

      .card {
        width: 32%;
        background-color: unset;
        border-radius: 20px;
        box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
        animation: slide-bottom 0.5s both;

        margin: 20px 0;

        &:hover {
          animation: slide-top 0.5s both;
          .img .arrow {
            display: flex;
          }
        }

        .img::before {
          content: "";
          position: absolute;
          background-color: #0b4df5;
          opacity: 0.3;
          top: -190px;
          left: 300px;
          width: 550px;
          height: 590px;
          z-index: 1;
          transition: transform 1s;
          transform-origin: 0 0;
          transform: scaleX(0);
          rotate: 45deg;
        }

        .img:hover {
          &::before {
            transform: scaleX(1);
          }
        }

        .img {
          position: relative;
          overflow: hidden;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          img {
            width: 100%;
            height: 100%;
          }

          .arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70px;
            height: 70px;
            display: none;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f2830d;
            color: #ffffff;
            z-index: 2;
            cursor: pointer;
          }
        }

        .content {
          padding: 40px;

          h4 {
            font-size: 20px;
            font-weight: 700;
            line-height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .container {
      .cards {
        .card {
          .content {
            padding: 30px;
            h4 {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    .container {
      padding: 100px 0;

      h3 {
        margin-bottom: 100px;
      }
      .cards {
        .card {
          width: 47%;
          margin-top: unset;
          .content {
            padding: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .container {
      padding: 80px 0;

      h3 {
        margin-bottom: 80px;
      }
      .cards {
        .card {
          width: 100%;
          margin-top: unset;
          .content {
            padding: 40px;
            h4 {
                font-size: 17px;
                line-height: 23px;
              }
          }
        }
      }
    }
  }
`;
