import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  .left {
    width: 48%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: 48%;

    h4 {
      font-size: 25px;
      margin-bottom: 20px;
      color: #000;
    }

    .wrapper {
      padding: 20px;

      p {
        font-size: 15px;
        line-height: 25px;
        text-align: justify;
        margin-bottom: 20px;

        a{
          color : #f2830d;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .right {
      h4 {
        font-size: 20px;
        margin-bottom: 15px;
      }

      .wrapper {
        p {
          font-size: 15px;
          line-height: 25px;
          text-align: justify;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    .left {
      width: 100%;
      margin-bottom: 20px;
    }
    .right {
      width: 100%;

      h4 {
        font-size: 15px;
        margin-bottom: 10px;
        color: #000;
      }

      .wrapper {
        p {
          font-size: 13px;
          line-height: 23px;
          text-align: justify;
        }
      }
    }
  }
`;
