import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a8;
  z-index: 100;
`;

export const ModalWrapper = styled.div`
  width: 80%;
  max-height: 80%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;

  .Wrapper {
    display: flex;
    justify-content: end;

    .cross {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2830d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
