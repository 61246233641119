import React from "react";
import { AboutContainer, Left, Right } from "./style";
import img from "../../../../Assets/Images/handshake2.jpg";
import { useTranslation } from "react-i18next";

export default function About() {

    const { t } = useTranslation();

  return (
    <AboutContainer id="about">
      <Right>
        <h3>{t('about.aboutH3')}</h3>
        <p>
          {t("about.aboutP1")}
          {" "}
          <span>CAPVIEW ENGINEERING & BUILDING</span>
          {" "}
          {t("about.aboutP2")}
        </p>
      </Right>
      <Left>
        <img src={img} alt="CAPVIEW ENGINEERING & BUILDING" />
        <div className="overlay"></div>
      </Left>
    </AboutContainer>
  );
}
