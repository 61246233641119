export const Links = [
  {
    pagename: "home",
    path: "/",
  },
  {
    pagename: "about",
    path: "#about",
  },
  {
    pagename: "our_businesses",
    path: "#metiers",
  },
  {
    pagename: "partner",
    path: "#partners",
  },
  // {
  //   pagename: "client",
  //   path: "",
  // },
  // {
  //   pagename: "achievements",
  //   path: "",
  // },
  {
    pagename: "contact",
    path: "#Contact",
  },
];
