import styled from "styled-components";

export const NavContainer = styled.div`
  @keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-2px);
    }
  }
  @keyframes slide-bottom {
    0% {
      transform: translateY(-2px);
    }
    100% {
      transform: translateY(0);
    }
  }
  a {
    display: block;
    width: 100%;
    padding: 13px;
    text-align: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0 8px 24px hsl(210deg 8% 62% / 20%);
    margin-bottom: 10px;
    background-color: #f2830d;
    color: #FFFFFF;
    cursor: pointer;
    animation: slide-bottom 0.5s both;
  }
  a:hover {
    animation: slide-top 0.5s both;
  }
`;
