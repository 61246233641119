import React, { useState } from "react";
import { Container } from "./style";
import b1 from "../../../../../Assets/Images/b1.jpg";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../Components/Icon";

export default function Engineering() {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t("Engineering.list1.p"),
      list: [
        t("Engineering.list1.value1"),
        t("Engineering.list1.value2"),
        t("Engineering.list1.value3"),
        t("Engineering.list1.value4"),
        t("Engineering.list1.value5"),
        t("Engineering.list1.value6"),
        t("Engineering.list1.value7"),
        t("Engineering.list1.value8"),
      ],
    },
    {
      id: 2,
      title: t("Engineering.list2.p"),
      list: [
        t("Engineering.list2.value1"),
        t("Engineering.list2.value2"),
        t("Engineering.list2.value3"),
        t("Engineering.list2.value4"),
        t("Engineering.list2.value5"),
        t("Engineering.list2.value6"),
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Container>
      <div className="left">
        <img src={b1} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title1")}</h4>
        <p>{t("Engineering.EngineeringP")}</p>
        <div className="content">
          {tabs.map((tab) => (
            <div className="list-1" key={tab.id}>
              <div className="p" onClick={() => handleTabClick(tab.id)}>
                <div className="Icon">
                  <Icon iconName="ArrowDown" />
                </div>
                {tab.title}
              </div>
              <ul className={tab.id === activeTab ? "" : "hidden"}>
                {tab.list.map((item, ind) => (
                  <li key={ind}>
                    <div className="cube"></div>
                    <div className="text">{item}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
