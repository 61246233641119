import React from "react";
import { ModalContainer, ModalWrapper } from "./style";
import cross from "../../Assets/Images/cross.png";

export default function Modal({ Component, isOpen }) {
  return (
    <ModalContainer>
      <ModalWrapper>
        <div className="Wrapper">
          <div
            className="cross"
            onClick={() => {
              isOpen(false);
            }}
          >
            <img src={cross} alt="CAPVIEW Engineering & Building" />
          </div>
        </div>
        <Component />
      </ModalWrapper>
    </ModalContainer>
  );
}
