import styled from "styled-components";

export const NavContainer = styled.nav`
  position: fixed;
  margin: 0 auto -104px auto;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1600px;
  width: 80%;
  height: 74px;
  background-color: #fff;
  border-radius: 110px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 10;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);

  .links {
    a {
      font-size: 18px;
      margin: 0 15px;
      cursor: pointer;
    }
    a:last-child {
      background-color: #f2830d;
      color: #FFFFFF;
      padding: 10px;
      border-radius: 5px;
    }
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: 50px;
    padding: 0 25px;

    .logo {
      img {
        width: 60%;
      }
    }
    .links {
      display: none;
    }
    .actions {
      .Menu {
        img {
          width: 60%;
        }
      }
    }
  }

  .active {
    color: #f2830d;
  }

  .actions {
    .Menu {
      cursor: pointer;
    }
  }
`;
