import styled from "styled-components";

export const MenuListContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a8;
  z-index: 100;
  animation-duration: 0.4s;

  .MenuList {
    width: 365px;
    height: 100%;
    background-color: #ffffff;
    padding: 30px;
    transition: .3s .3s;
    animation-duration: 0.4s;

    .cross:hover {
      background-color: #92d051;
    }

    .cross {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2830d;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 30px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .Langue{
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
        color: #000;
      }
    }

    .small{
      display: none;
    }

    .Contact {
      margin-top: 20px;

      h2 {
        font-size: 30px;
        margin-bottom: 20px;
        color: #000;
      }

      ul li a {
        display: flex;
        margin-bottom: 10px;
        font-size: 18px;
        color: #6F737B;

        .Icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          color: #f2830d;
        }
      }
    }
  }
  @media (max-width: 1000px){
    .MenuList{
      width: 315px;

      .small{
        display: block;
      }
    }
  }
`;
