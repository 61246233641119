import React from "react";
import { NavContainer } from "./style";
import logo from "../../Assets/Images/logo2.jpg";
import Menu from "../../Assets/Images/Menu.png";
import { useTranslation } from "react-i18next";
import { Links } from "../../Utils/Links.js";

export default function Nav({ isOpen }) {
  const { t } = useTranslation();

  function handleSmoothScroll(event) {
    const target = event.target.getAttribute("href");
    if (target !== "/") {
      event.preventDefault();
      document.querySelector(target).scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  return (
    <NavContainer>
      <div className="logo">
        <a href="/">
          <img src={logo} alt="CAPVIEW Engineering & Building" />
        </a>
      </div>
      <div className="links">
        {Links.map((link, index) => {
          return (
            <a
              href={link.path}
              onClick={handleSmoothScroll}
              className={link.path === "/" ? "active" : ""}
              key={index}
            >
              {t(`pages.${link.pagename}`)}
            </a>
          );
        })}
      </div>
      <div className="actions">
        <div
          className="Menu"
          onClick={() => {
            isOpen(true);
          }}
        >
          <img src={Menu} alt="CAPVIEW Engineering & Building" />
        </div>
      </div>
    </NavContainer>
  );
}
