import styled from "styled-components";
import waves3 from "../../Assets/Svg/wave3.svg";

export const FooterWraper = styled.div`
  background: url("${waves3}") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-color: #f2830d;
  color: #fff;

  .container {
    max-width: 1600px;
    width: 80%;
    padding: 100px 0;
    margin: 0 auto;

    .cards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 50px;

      .card {
        width: 30%;

        h5 {
          font-size: 17px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 20px;
        }

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 25px;
          text-align: justify;
          margin-bottom: 20px;
        }

        .media {
          display: flex;

          .Icon {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #f2830d;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }

        ul {
          li {
            font-size: 15px;
            font-weight: 500;
            line-height: 25px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .cube {
              width: 10px;
              height: 10px;
              background-color: #f2830d;
              margin-right: 10px;
            }

            a {
              color: #fff;
            }

            .Icon {
              display: flex;
              align-items: center;
              color: #f2830d;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .copyRights {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1000px) {
    .container {
      padding: 80px 0;
      
      .cards {
        .card {
          width: 100%;
          margin-bottom: 30px;

          h5 {
            font-size: 15px;
            font-weight: 600;
            line-height: 28px;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }

      .copyRights{
        flex-direction: column;
        text-align: center;
      }
    }
  }
`;
