import React from "react";
import Nav from "../../../../Components/Nav";
import { HeaderContainer } from "./style";
import Slider from "react-slick";
import "../../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import slide1 from "../../../../Assets/Images/slide1.jpg";
import slide3 from "../../../../Assets/Images/slide3.jpg";
import slide4 from "../../../../Assets/Images/slide4.jpg";
import { useTranslation } from "react-i18next";

export default function Header({ isOpen }) {
  const { t } = useTranslation();

  function handleSmoothScroll(event) {
    event.preventDefault();
    const target = event.target.getAttribute('href');
    document.querySelector(target).scrollIntoView({
      behavior: 'smooth'
    });
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  return (
    <HeaderContainer id="Header">
      <Nav isOpen={isOpen} />
      <Slider {...settings} className="slider">
        <div className="custom-slide">
          <img src={slide1} alt="image1" />
        </div>
        <div className="custom-slide">
          <img src={slide3} alt="image3" />
        </div>
        <div className="custom-slide">
          <img src={slide4} alt="image3" />
        </div>
      </Slider>
      <div className="Content">
        <div className="text">
          {t("header.content")}
        </div>
        <a href="#Contact" onClick={handleSmoothScroll} className="btn">{t('header.button')}</a>
      </div>
    </HeaderContainer>
  );
}
