import React from "react";
import { MenuListContainer } from "./style";
import cross from "../../Assets/Images/cross.png";
import Icon from "../Icon";
import Select from "react-select";
import i18n from "../../i18n";
import Hr from "../Hr";
import NavLinks from "../NavLinks";
import { Links } from "../../Utils/Links";

const Langs = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "Français",
  },
];

const Contacts = [
  {
    title: "+212 6 60 77 62 67",
    icon: "mobile",
    path: "tel:+212 6 60 77 62 67",
  },
  {
    title: "+212 5 37 77 63 64",
    icon: "phone",
    path: "tel:+212 5 37 77 63 64",
  },
  {
    title: "contact@capview-e-b.com",
    icon: "email",
    path: "mailto:contact@capview-e-b.com",
  },
];

export default function MenuList({ isOpen }) {
  return (
    <MenuListContainer>
      <div className="MenuList">
        <div
          className="cross"
          onClick={() => {
            isOpen(false);
          }}
        >
          <img src={cross} alt="CAPVIEW Engineering & Building" />
        </div>
        <div className="Langue">
          <h2>Langue</h2>
          <Select
            options={Langs}
            defaultValue={Langs.find(function (a) {
              return a.value === i18n.resolvedLanguage;
            })}
            onChange={(lang) => {
              i18n.changeLanguage(lang.value);
            }}
            isSearchable={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#92d051" : "#f2830d",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              dropdownIndicator: () => ({
                color: "#f2830d",
                marginRight: "5px",
              }),
            }}
          />
        </div>
        <div className="small">
          <Hr />
          <div className="Menu">
            {Links.map((link, index) => {
              return (
                <NavLinks
                  key={index}
                  pagename={link.pagename}
                  path={link.path}
                />
              );
            })}
          </div>
          <Hr />
        </div>
        <div className="Contact">
          <h2>Contacts</h2>
          <ul>
            {Contacts.map((items, index) => {
              return (
                <li key={index}>
                  <a href={items.path}>
                    <div className="Icon">
                      <Icon iconName={items.icon} width="20px" height="20px" />
                    </div>
                    {items.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </MenuListContainer>
  );
}
