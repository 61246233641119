import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Left, OffersContainer, Right } from "./style";
import offers from "../../../../Assets/Images/offers.jpg";
import Icon from "../../../../Components/Icon";

export default function Offers() {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t("offres.value1.title"),
      content: t("offres.value1.content"),
      subContent: [
        t("offres.value1.subContent.value1"),
        t("offres.value1.subContent.value2"),
        t("offres.value1.subContent.value3"),
      ],
    },
    {
      id: 2,
      title: t("offres.value2.title"),
      content: t("offres.value2.content"),
    },
    {
      id: 3,
      title: t("offres.value3.title"),
      content: t("offres.value3.content"),
      subContent: [
        t("offres.value3.subContent.value1"),
        t("offres.value3.subContent.value2"),
        t("offres.value3.subContent.value3"),
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <OffersContainer>
      <Left>
        <div className="container">
          <h3>{t("offres.offresH3")}</h3>
          <div className="actions">
            <ul>
              {tabs.map((tab) => (
                <>
                  <li
                    key={tab.id}
                    className={tab.id === activeTab ? "active" : ""}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <div className="Icon">
                      <Icon iconName="grid" />
                    </div>
                    {tab.title}
                  </li>
                  <div
                    key={tab.id}
                    className={
                      tab.id === activeTab ? "content" : "content hidden"
                    }
                  >
                    {tab.content}
                  </div>

                  {tab.id === 1 && activeTab === 1 && (
                    <ul className="extraUl">
                      {tab.subContent.map((item, index) => (
                        <li key={index}>
                          <div className="cube"></div>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}

                  {tab.id === 3 && activeTab === 3 && (
                    <ul className="extraUl">
                    {tab.subContent.map((item, index) => (
                      <li key={index}>
                        <div className="cube"></div>
                        {item}
                      </li>
                    ))}
                  </ul>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </Left>
      <Right>
        <img src={offers} alt="CAPVIEW ENGINEERING & BUILDING" />
      </Right>
    </OffersContainer>
  );
}
