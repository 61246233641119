import React from "react";
import { useTranslation } from "react-i18next";
import { PartnersContainer } from "./style";
import partner1 from "../../../../Assets/Images/Encardio.png";

export default function Partners() {
  const { t } = useTranslation();

  const content = [
    {
      img: partner1,
      title: "ENCARDIO RITE",
      text: t("Partners.p"),
      path: "https://www.encardio.com/",
    },
  ];

  return (
    <PartnersContainer>
      <div className="container" id="partners">
        <h3>{t("Partners.PartnersH3")}</h3>
        <div className="cards">
          {content.map((card, index) => {
            return (
              <div className="card" key={index}>
                <a href={card.path} target="blank">
                  <img src={card.img} alt="CAPVIEW ENGINEERING & BUILDING" />
                </a>
                <h5>{card.title}</h5>
                <p>{card.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </PartnersContainer>
  );
}
