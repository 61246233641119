import React from "react";
import { useTranslation } from "react-i18next";
import { ValuesContainer } from "./style";
import value1 from "../../../../Assets/Images/value1.png";
import value2 from "../../../../Assets/Images/value2.png";
import value3 from "../../../../Assets/Images/value3.png";

export default function Values() {

  const { t } = useTranslation();

  const content = [
    {
      img: value1,
      title: t("values.value1.title"),
      text: t("values.value1.content"),
    },
    {
      img: value2,
      title: t("values.value2.title"),
      text: t("values.value2.content"),
    },
    {
      img: value3,
      title: t("values.value3.title"),
      text: t("values.value3.content"),
    },
  ];

  return (
    <ValuesContainer>
      <div className="container">
        <h3>{t("values.valuesH3")}</h3>
        <div className="cards">
          {content.map((card, index) => {
            return (
              <div className="card" key={index}>
                <img src={card.img} alt="CAPVIEW ENGINEERING & BUILDING" />
                <h5>{card.title}</h5>
                <p>{card.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </ValuesContainer>
  );
}
