import React, { useState } from "react";
import { Container } from "./style";
import b6 from "../../../../../Assets/Images/b6.jpg";
import b55 from "../../../../../Assets/Images/b55.jpg";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../Components/Icon";

export default function Infrastructure() {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t("Infrastructure.list1.p"),
      list: [
        t("Infrastructure.list1.value1"),
        t("Infrastructure.list1.value2"),
        t("Infrastructure.list1.value3"),
      ],
    },
    {
      id: 2,
      title: t("Infrastructure.list2.p"),
      list: [
        t("Infrastructure.list2.value1"),
        t("Infrastructure.list2.value2"),
        t("Infrastructure.list2.value3"),
        t("Infrastructure.list2.value4"),
        t("Infrastructure.list2.value5"),
        t("Infrastructure.list2.value6"),
        t("Infrastructure.list2.value7"),
      ],
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [img, setImg] = useState(true);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const handleShowImg = (tabId) => {
    if(tabId === 1){
        setImg(true);
    }else{
        setImg(false);
    }
  };

  return (
    <Container>
      <div className="left">
        <img src={img ? b55 : b6} alt="CAPVIEW ENGINEERING & BUILDING" />
      </div>
      <div className="right">
        <h4>{t("businesses.title6")}</h4>
        <div className="content">
          {tabs.map((tab) => (
            <div className="list-1" key={tab.id}>
              <div className="p" onClick={() => {handleTabClick(tab.id); handleShowImg(tab.id);}}>
                <div className="Icon">
                  <Icon iconName="ArrowDown" />
                </div>
                {tab.title}
              </div>
              <ul className={tab.id === activeTab ? "" : "hidden"}>
                {tab.list.map((item, ind) => (
                  <li key={ind}>
                    <div className="cube"></div>
                    <div className="text">{item}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
