import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BusinessesContainer } from "./style";
import b1 from "../../../../Assets/Images/b1.jpg";
import b2 from "../../../../Assets/Images/b2.jpg";
import b3 from "../../../../Assets/Images/b3.jpg";
import b4 from "../../../../Assets/Images/b4.jpg";
import b5 from "../../../../Assets/Images/b66.jpg";
import b6 from "../../../../Assets/Images/b5.jpg";
import Icon from "../../../../Components/Icon";
import Details from "../Details";

export default function Businesses() {
  const { t } = useTranslation();

  const content = [
    {
      img: b1,
      title: t("businesses.title1"),
      href: "/Ingenierie&methode",
      detail: "Engineering",
    },
    {
      img: b2,
      title: t("businesses.title2"),
      detail: "Advisory",
    },
    {
      img: b3,
      title: t("businesses.title3"),
      detail: "Digitalisation",
    },
    {
      img: b4,
      title: t("businesses.title4"),
      detail: "Railway",
    },
    {
      img: b5,
      title: t("businesses.title6"),
      detail: "Infrastructure",
    },
    {
      img: b6,
      title: t("businesses.title5"),
      detail: "Environment",
    },
  ];

  const [Modals, setModals] = useState(false);
  const [detailselected, setDetailSelected] = useState("");

  const isOpen = (value) => {
    setModals(value)
  }

  return (
    <>
      <BusinessesContainer>
        <div className="container" id="metiers">
          <h3>{t("businesses.businessesH3")}</h3>
          <div className="cards">
            {content.map((card, index) => {
              return (
                <div className="card" key={index}>
                  <div className="img">
                    <img src={card.img} alt="CAPVIEW ENGINEERING & BUILDING" />
                    <div
                      className="arrow"
                      onClick={() => {
                        setModals(true);
                        setDetailSelected(card.detail);
                      }}
                    >
                      <Icon iconName="arrowRight" width="30px" height="30px" />
                    </div>
                  </div>
                  <div className="content">
                    <h4>{card.title}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BusinessesContainer>
      {Modals && <Details isOpen={isOpen} detailselected={detailselected} />}
    </>
  );
}
