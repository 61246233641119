import styled from "styled-components";

export const AboutContainer = styled.div`
  max-width: 1600px;
  width: 80%;
  padding: 150px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 90%;
    padding: 100px 0;
    flex-direction: column-reverse;
  }
`;
export const Right = styled.div`
  width: 45%;

  p {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    text-align: justify;
    margin: 80px 0;

    span {
      font-weight: 700;
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: end;
    a {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
      color: #f2830d;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 1350px) {
    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 35px;
      margin: 30px 0;

      span {
        font-weight: 600;
      }
    }
  }

  @media (max-width: 1000px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    h3 {
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 36px;
      margin: 40px 0;

      span {
        font-weight: 600;
      }
    }

    .action {
      a {
        font-size: 22px;
        font-weight: 500;
        line-height: 29px;
        color: #f2830d;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
      }
    }
  }
`;
export const Left = styled.div`
  width: 45%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    rotate: -5deg;
    transition: .3s .3s;
  }

  .overlay{
    position: absolute;
    top: 0;
    background-color: #f2830d;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: -1;
  }

  &:hover img{
    rotate: 5deg;
  }

  // @media (max-width: 1350px) {
  //   img {
  //     height: 450px;
  //   }
  // }

  @media (max-width: 1000px) {
    width: 90%;
    margin: 0 auto 50px auto;

    // img {
    //     height: auto;
    //   }
  }

  // @media (max-width: 700px) {
  //   img {
  //     height: auto;
  //   }
  // }
`;
