import React from "react";
import Icon from "../Icon";
import { FooterWraper } from "./style";
import { useTranslation } from "react-i18next";
import { Links } from "../../Utils/Links";

export default function Footer() {
  const { t } = useTranslation();

  function handleSmoothScroll(event) {
    const target = event.target.getAttribute("href");
    if (target !== "/") {
      event.preventDefault();
      document.querySelector(target).scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  return (
    <FooterWraper>
      <footer className="container">
        <div className="cards">
          <div className="card">
            <h5>CAPVIEW ENGINEERING & BUILDING</h5>
            <p>{t("footer.footerP")}</p>
            <div className="media">
              <a href="https://web.facebook.com/CPVEB">
                <div className="Icon">
                  <Icon iconName="facebook" />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/capview-engineering-and-building">
                <div className="Icon">
                  <Icon iconName="linkedIn" />
                </div>
              </a>
            </div>
          </div>
          <div className="card">
            <h5>{t("footer.Links")}</h5>
            <ul>
              {Links.map((link, index) => {
                return (
                  <li>
                    <div className="cube"></div>
                    <a
                      href={link.path}
                      onClick={handleSmoothScroll}
                      className={link.path === "/" ? "active" : ""}
                      key={index}
                    >
                      {t(`pages.${link.pagename}`)}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="card">
            <h5>Contacts</h5>
            <ul>
              <li>
                <div className="Icon">
                  <Icon iconName="marker" />
                </div>
                15, Avenue AL Abtal, appt n 4, Agdal - Rabat
              </li>
              <li>
                <div className="Icon">
                  <Icon iconName="mobile" />
                </div>
                <a href="tel:+212 6 60 77 62 67">+212 6 60 77 62 67</a>
              </li>
              <li>
                <div className="Icon">
                  <Icon iconName="phone" />
                </div>
                <a href="tel:+212 5 37 77 63 64">+212 5 37 77 63 64</a>
              </li>
              <li>
                <div className="Icon">
                  <Icon iconName="email" />
                </div>
                <a href="mailto:contact@capview-e-b.com">
                  contact@capview-e-b.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyRights">
          <p>Copyright © 2023 CAPVIEW ENGINEERING & BUILDING</p>
          <p>
            Made by <a href="mailto:yassine.bentaoui@gmail.com">YASBENDEV</a>
          </p>
        </div>
      </footer>
    </FooterWraper>
  );
}
