import styled from "styled-components";

export const OffersContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1000px) {
    width: 90%;
    padding: 100px 0;
    margin: 0 auto;
  }
`;
export const Left = styled.div`
  width: 50%;
  padding: 80px 0;

  .container {
    max-width: 800px;
    padding: 0 40px;
    margin-left: auto;

    h3 {
      margin-bottom: 100px;
    }

    .actions {
      ul {
        li {
          display: flex;
          font-size: 22px;
          font-weight: 700;
          color: #f2830d82;
          cursor: pointer;
          margin-bottom: 30px;

          .Icon {
            margin-right: 10px;
          }
        }

        li.active {
          color: #f2830d;
        }

        .extraUl {
          margin: 0 15px 20px 15px;

          li {
            font-size: 15px;
            font-weight: 500;
            line-height: 25px;
            color: #000;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .cube {
              width: 10px;
              height: 10px;
              background-color: #f2830d;
              margin-right: 10px;
            }
          }
        }

        .content {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          text-align: justify;
          margin: 20px 0;
        }

        .hidden {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1400px) & (min-width: 1001px) {
    .container {
      h3 {
        margin-bottom: 80px;
      }

      .actions {
        ul {
          li {
            font-size: 20px;
          }

          .content {
            font-size: 16px;
            line-height: 26px;
          }

          .extra {
            p {
              font-size: 15px;
              line-height: 25px;
            }

            ul {
              li {
                font-size: 13px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    padding: unset;

    .container {
      max-width: unset;
      padding: unset;

      h3 {
        text-align: center;
      }

      .actions {
        ul {
          .extra {
            margin: 40px 10px;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      .actions {
        ul {
          li {
            font-size: 18px;
          }

          .content {
            font-size: 15px;
            line-height: 25px;
          }

          .extra {
            p {
              font-size: 14px;
              line-height: 24px;
            }

            ul {
              li {
                font-size: 12px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
`;
export const Right = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;
