import React from "react";
import { ContactContainer, ErrorMsg, SuccessMsg } from "./style";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import contact from "./contact.php";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export default function Contact() {
  const { t } = useTranslation();

  const schema = yup
  .object({
    name: yup.string().required(t("Contact.nameError")),
    email: yup
      .string()
      .email(t("Contact.emailError"))
      .required(t("Contact.emailErrorMatch")),
    tel: yup
      .string()
      .required(t("Contact.telError"))
      .matches(phoneRegExp, t("Contact.telErrorMatch")),
    objet: yup.string(),
    message: yup.string().required(t("Contact.messageError")),
  })
  .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [objet, setObjet] = useState("");
  const [message, setMessage] = useState("");
  const [, setErr] = useState("");
  const [success, setSuccess] = useState("");

  function onSubmit() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("tel", tel);
    formData.append("objet", objet);
    formData.append("message", message);
    fetch(contact, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess("Message Send");
        } else {
          setErr(data.message);
          // console.log("Message failed to send.");
        }
      });
  }

  return (
    <ContactContainer id="Contact">
      <h3>{t("pages.contact")}</h3>
      <p>{t("Contact.p")}</p>
      <div className="FormContainer">
        <div className="fromInputs">
          <div className="fromGroupe">
            {errors.name?.message && (
              <ErrorMsg>{errors.name?.message}</ErrorMsg>
            )}
            <input
              {...register("name")}
              name="name"
              type="text"
              placeholder={t("Contact.namePlace")}
              value={name}
              className={errors.name?.message ? "error" : ""}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="fromGroupe">
            {errors.email?.message && (
              <ErrorMsg>{errors.email?.message}</ErrorMsg>
            )}
            <input
              {...register("email")}
              name="email"
              type="text"
              placeholder="Email*"
              value={email}
              className={errors.email?.message ? "error" : ""}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="fromGroupe">
            {errors.tel?.message && <ErrorMsg>{errors.tel?.message}</ErrorMsg>}
            <input
              {...register("tel")}
              name="tel"
              type="text"
              placeholder="Tel*"
              value={tel}
              className={errors.tel?.message ? "error" : ""}
              onChange={(e) => setTel(e.target.value)}
              required
            />
          </div>
          <div className="fromGroupe">
            <input
              {...register("objet")}
              name="objet"
              type="text"
              placeholder={t("Contact.objetPlace")}
              value={objet}
              onChange={(e) => setObjet(e.target.value)}
            />
          </div>
        </div>
        {errors.message?.message && (
          <ErrorMsg>{errors.message?.message}</ErrorMsg>
        )}
        <textarea
          {...register("message")}
          name="message"
          placeholder="Message*"
          value={message}
          className={errors.message?.message ? "error" : ""}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <button onClick={handleSubmit(onSubmit)}>{t("Contact.btn")}</button>
        <SuccessMsg>{success}</SuccessMsg>
      </div>
    </ContactContainer>
  );
}
