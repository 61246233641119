import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavContainer } from './style'

export default function NavLinks({ key, pagename, path}) {

    const { t } = useTranslation();

  return (
    <NavContainer>
      <a key={key} href={path}>{t(`pages.${pagename}`)}</a>
    </NavContainer>
  )
}
