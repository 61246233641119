import React from "react";
import Modal from "../../../../Components/Modal";
import Engineering from "./Engineering";
import Advisory from "./Advisory";
import Digitalisation from "./Digitalisation";
import Railway from "./Railway";
import Environment from "./Environment";
import Infrastructure from "./Infrastructure";

export default function Details({ detailselected, isOpen }) {
  const selectedContent = () => {
    switch (detailselected) {
      case "Engineering":
        return Engineering;

      case "Advisory":
        return Advisory;

      case "Digitalisation":
        return Digitalisation;

      case "Railway":
        return Railway;

      case "Infrastructure":
        return Infrastructure;

      case "Environment":
        return Environment;

      default:
        return "test";
    }
  };
  return <Modal isOpen={isOpen} Component={selectedContent()} />;
}
